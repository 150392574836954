import {relations} from 'drizzle-orm';
import {
  boolean,
  integer,
  json,
  jsonb,
  pgEnum,
  pgTable,
  primaryKey,
  serial,
  text,
  timestamp,
  varchar,
} from 'drizzle-orm/pg-core';
import {Location, statusEnum} from '.';
import {notifications} from './base';
import {storeChats} from './chat';
import {customers} from './customer';
import {storeToCarrier} from './order';
import {coupons, products} from './product';

export const storeStatus = pgEnum('store_status', [
  'NEW',
  'ACTIVE',
  'DISABLED',
  'IN_REVIEW',
  'SUSPENDED',
]);

export const stores = pgTable('stores', {
  id: serial('id').primaryKey(),
  storeName: varchar('store_name', {
    length: 256,
  }).notNull(),
  email: varchar('email', {
    length: 256,
  })
    .unique()
    .notNull(),
  storeCode: varchar('store_code', {
    length: 256,
  })
    .unique()
    .notNull(),
  bio: text('bio').notNull(),
  image: varchar('image', {
    length: 256,
  }),
  status: storeStatus('status').default('NEW'),
  bankAccount: json('bank_account').$type<{
    name: string;
    accountNumber: string;
    bank: string;
  }>(),
  phoneNumber: varchar('phone_number', {length: 256}),
  verified: boolean('verified'),
  location: json('location').$type<Location>().notNull(),
  internal: boolean('internal'),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at').defaultNow().notNull(),
});

export const storeRelations = relations(stores, ({many}) => ({
  coupons: many(coupons),
  followers: many(storeFollowers),
  products: many(products),
  storeVendors: many(storesToVendors),
  storeCarriers: many(storeToCarrier),
  chats: many(storeChats),
}));

export type Store = typeof stores.$inferSelect;
export type StoreWithFollowers = Store & {
  followers: (typeof storeFollowers.$inferSelect)[];
};
export type StoreWithVendors = Store & {
  storeVendors: (typeof storesToVendors.$inferSelect & {
    vendor: Vendor;
  })[];
};

export const storeVendorRole = pgEnum('store_vendor_role', ['OWNER', 'MEMBER']);

export const storesToVendors = pgTable(
  'stores_to_vendors',
  {
    storeId: integer('store_id')
      .notNull()
      .references(() => stores.id),
    role: storeVendorRole('role').notNull().default('MEMBER'),
    vendorId: integer('vendor_id')
      .notNull()
      .references(() => vendors.id),
  },
  t => ({
    pk: primaryKey({
      columns: [t.storeId, t.vendorId],
    }),
  })
);

export const storeToVendorRelations = relations(storesToVendors, ({one}) => ({
  store: one(stores, {
    fields: [storesToVendors.storeId],
    references: [stores.id],
  }),
  vendor: one(vendors, {
    fields: [storesToVendors.vendorId],
    references: [vendors.id],
  }),
}));

export const vendorRole = pgEnum('vendor_role', [
  'ADMIN',
  'VENDOR',
  'OPERATION',
]);

export const vendors = pgTable('vendors', {
  id: serial('id').primaryKey(),
  firstName: varchar('first_name', {
    length: 256,
  }).notNull(),
  lastName: varchar('last_name', {
    length: 256,
  }).notNull(),
  email: varchar('email', {
    length: 256,
  }).notNull(),
  image: varchar('image', {
    length: 256,
  }),
  status: statusEnum('status').default('ACTIVE'),
  role: vendorRole('role').notNull(),
  phoneNumber: varchar('phone_number', {length: 256}),
  location: jsonb('location').$type<Location>(),
  internal: boolean('internal'),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at').defaultNow().notNull(),
});

export const vendorRelations = relations(vendors, ({one, many}) => ({
  vendorStores: many(storesToVendors),
  credentials: one(vendorCredentialsAuths),
  notifications: many(notifications),
}));

export type Vendor = typeof vendors.$inferSelect;

export const vendorCredentialsAuths = pgTable('vendor_credentials_auths', {
  id: serial('id').primaryKey(),
  vendorId: integer('vendor_id')
    .references(() => vendors.id)
    .notNull(),
  accessId: varchar('access_id', {
    length: 256,
  })
    .notNull()
    .unique(),
  encrypedPassword: varchar('encrypted_password', {length: 256}).notNull(),
  status: statusEnum('status').default('ACTIVE'),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  modifiedAt: timestamp('modified_at', {withTimezone: true}),
  lastSignedIn: timestamp('last_signed_in'),
});

export const vendorCredentialsAuthsR = relations(
  vendorCredentialsAuths,
  ({one}) => ({
    vendor: one(vendors, {
      fields: [vendorCredentialsAuths.vendorId],
      references: [vendors.id],
    }),
  })
);

export const storeFollowers = pgTable(
  'customers_to_stores',
  {
    customerId: integer('customer_id')
      .notNull()
      .references(() => customers.id),
    followedAt: timestamp('followed_at', {withTimezone: true})
      .defaultNow()
      .notNull(),
    storeId: integer('store_id')
      .notNull()
      .references(() => stores.id),
  },
  t => ({
    pk: primaryKey({
      columns: [t.customerId, t.storeId],
    }),
  })
);

export type StoreFollower = typeof storeFollowers.$inferSelect;

export const storeFollowerRelations = relations(storeFollowers, ({one}) => ({
  customer: one(customers, {
    fields: [storeFollowers.customerId],
    references: [customers.id],
  }),
  store: one(stores, {
    fields: [storeFollowers.storeId],
    references: [stores.id],
  }),
}));
