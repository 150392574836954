import {relations} from 'drizzle-orm';
import {
  integer,
  json,
  pgEnum,
  pgTable,
  primaryKey,
  serial,
  timestamp,
  varchar,
} from 'drizzle-orm/pg-core';
import {statusEnum} from '.';
import {storeOrders} from './order';
import {products, variants} from './product';
import {stores} from './store';

export const penaltyStatus = pgEnum('penalty_status', ['PENDING', 'SETTLED']);

export const violationType = pgEnum('violation_type', [
  'QUALITY_CONTROL',
  'FAILURE_TO_SHIP',
  'COUNTERFEIT_PRODUCT',
  'PROHIBITED_PRODUCT',
]);

export const penaltyAction = pgEnum('penalty_action', [
  'FINANCIAL',
  'SUSPENSION',
  'PERMANENT_BAN',
  'DELISTING',
  'WARNING',
]);

export const penalties = pgTable('penalties', {
  id: serial('id').primaryKey(),
  storeId: integer('store_id')
    .references(() => stores.id)
    .notNull(),
  storeOrderId: integer('store_order_id')
    .references(() => storeOrders.id)
    .notNull(),
  variantId: integer('variant_id').references(() => variants.id),
  violation: violationType('violation').notNull(),
  action: penaltyAction('action').notNull(),
  description: varchar('description', {length: 500}).notNull(),
  comments: varchar('comments', {
    length: 500,
  }),
  value: integer('value').notNull(),
  violatedAt: timestamp('violated_at', {withTimezone: true}).notNull(),
  updated: timestamp('updated_at', {
    withTimezone: true,
  })
    .notNull()
    .$defaultFn(() => new Date()),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .notNull()
    .defaultNow(),
  settledAt: timestamp('settled_at', {
    withTimezone: true,
  }),
  status: penaltyStatus('status').notNull().default('PENDING'),
});

export const penaltiesRelations = relations(penalties, ({one}) => ({
  storeOrder: one(storeOrders, {
    fields: [penalties.storeOrderId],
    references: [storeOrders.id],
  }),
  store: one(stores, {
    fields: [penalties.storeId],
    references: [stores.id],
  }),
  variant: one(variants, {
    fields: [penalties.variantId],
    references: [variants.id],
  }),
}));

export type Penalty = typeof penalties.$inferSelect;

export const promotionType = pgEnum('promotion_type', [
  'SLIDER',
  'IMAGE',
  'PRODUCTS',
  'STORE',
]);

export const promotions = pgTable('promotions', {
  id: serial('id').primaryKey(),
  title: varchar('title', {
    length: 256,
  }),
  type: promotionType('type').notNull(),
  storeId: integer('store_id').references(() => stores.id),
  status: statusEnum('status').notNull(),
  image: varchar('image', {
    length: 1000,
  }),
  expiresAt: timestamp('expires_at', {
    withTimezone: true,
  }),
  countdown: timestamp('countdown', {
    withTimezone: true,
  }),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
  }),
  links: json('links'),
  priority: integer('priority').notNull().default(1),
});

export const promotionsRelations = relations(promotions, ({one, many}) => ({
  store: one(stores, {
    fields: [promotions.storeId],
    references: [stores.id],
  }),
  promotionProducts: many(promotionToProduct),
}));

export type Promotion = typeof promotions.$inferSelect;

export const promotionToProduct = pgTable(
  'promotion_to_product',
  {
    promotionId: integer('promotion_id')
      .notNull()
      .references(() => promotions.id),
    productId: integer('product_id')
      .notNull()
      .references(() => products.id),
  },
  t => ({
    pk: primaryKey({
      columns: [t.promotionId, t.productId],
    }),
  })
);

export const promotionToProductRelations = relations(
  promotionToProduct,
  ({one}) => ({
    promotion: one(promotions, {
      fields: [promotionToProduct.promotionId],
      references: [promotions.id],
    }),
    product: one(products, {
      fields: [promotionToProduct.productId],
      references: [products.id],
    }),
  })
);
