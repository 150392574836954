import {relations} from 'drizzle-orm';
import {
  boolean,
  integer,
  pgEnum,
  pgTable,
  serial,
  timestamp,
  unique,
  varchar,
} from 'drizzle-orm/pg-core';
import {customers} from './customer';
import {coupons} from './product';
import {stores} from './store';

export const chatStatus = pgEnum('chat_status', [
  'ACTIVE',
  'DISABLED',
  'BANNED',
]);
export const storeMessageType = pgEnum('message_type', [
  'TEXT',
  'COUPON',
  'IMAGE',
  'VIDEO',
]);

export const storeChats = pgTable(
  'store_chats',
  {
    id: serial('id').primaryKey(),
    storeId: integer('store_id')
      .notNull()
      .references(() => stores.id),
    customerId: integer('customer_id')
      .notNull()
      .references(() => customers.id),
    createdAt: timestamp('created_at', {
      withTimezone: true,
    })
      .notNull()
      .defaultNow(),
    updatedAt: timestamp('updated_at', {
      withTimezone: true,
    })
      .notNull()
      .defaultNow()
      .$onUpdateFn(() => new Date()),
    status: chatStatus('status').default('ACTIVE'),
  },
  table => ({
    uniqueChats: unique().on(table.storeId, table.customerId),
  })
);

export const storeChatsRelations = relations(storeChats, ({one, many}) => ({
  messages: many(storeMessages),
  store: one(stores, {
    fields: [storeChats.storeId],
    references: [stores.id],
  }),
  customer: one(customers, {
    fields: [storeChats.customerId],
    references: [customers.id],
  }),
}));

export const storeMessages = pgTable('store_messages', {
  id: serial('id').primaryKey(),
  chatId: integer('chat_id')
    .notNull()
    .references(() => storeChats.id),
  senderId: integer('sender_id').notNull(),
  sentAt: timestamp('sent_at', {
    withTimezone: true,
  })
    .notNull()
    .defaultNow(),
  deleted: boolean('deleted'),
  type: storeMessageType('type'),
  messageText: varchar('text', {
    length: 1000,
  }).notNull(),
  mediaURL: varchar('mediaURL', {
    length: 256,
  }),
  couponId: integer('coupon_id').references(() => coupons.id),
});

export type StoreMessage = typeof storeMessages.$inferSelect;

export const storeMessagesRelations = relations(storeMessages, ({one}) => ({
  storeChat: one(storeChats, {
    fields: [storeMessages.chatId],
    references: [storeChats.id],
  }),
}));
