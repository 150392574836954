import {pgEnum} from 'drizzle-orm/pg-core';

export interface Location {
  address: string;
  city: string;
  state: string;
}

export const statusEnum = pgEnum('status', ['ACTIVE', 'DISABLED', 'IN_REVIEW']);

export function addHours(date: Date, hours: number): Date {
  const result = new Date(date);
  result.setHours(result.getHours() + hours);
  return result;
}
