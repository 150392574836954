import {Penalty} from './schema/operation';

export const DefaultCommission = 6;

export const MaxShippingHours = 96;
export const ShippingExtensionHours = 96;
export const AutomaticConfirmationInHours = 2 * 24;

export const PENALTY_VALUES: {
  [key in Penalty['violation']]: number;
} = {
  COUNTERFEIT_PRODUCT: 0,
  FAILURE_TO_SHIP: 0.4,
  PROHIBITED_PRODUCT: 0,
  QUALITY_CONTROL: 0,
};
