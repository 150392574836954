import {products} from '@delorand/db/schema/product';
import {createInsertSchema} from 'drizzle-zod';
import {z} from 'zod';

const requiredNumber = z
  .number({invalid_type_error: 'Invalid'})
  .min(1, 'Required')
  .default(0);

const zodNumber = z.number({invalid_type_error: 'Req'});

const fileSchema = z.object({
  serverUrl: z.string().nullish(),
  file: z.any().nullish(),
  previewUrl: z.string().nullish(),
});

export const orderReviewS = z.object({
  rating: z.number().default(0),
  body: z.string().min(1, 'Enter a valid review'),
  images: z.array(fileSchema),
});

export const variantS = z.array(
  z.object({
    attributes: z.array(
      z.object({
        name: z.string(),
        option: z.string().toLowerCase(),
      })
    ),
    productId: z.number().optional(),
    id: z.number().optional(),
    price: requiredNumber,
    stock: z.number({invalid_type_error: 'Invalid'}),
    sku: z.string(),
  })
);

export const productS = createInsertSchema(products, {
  brandId: z.string().or(z.number()),
  inPackage: z.array(z.string()).default([]),
  tags: z.array(z.string()).default([]),
})
  .extend({
    variants: variantS,
    otherBrands: z.string().optional(),
  })
  .omit({
    defaultPrice: true,
    slug: true,
    storeId: true,
    internal: true,
    sold: true,
    id: true,
    updatedAt: true,
    createdAt: true,
    viewsCount: true,
    images: true,
  });

const productImageS = fileSchema.extend({
  id: z.string(),
});

export const productFormS = createInsertSchema(products, {
  title: z.string().min(1, 'Required'),
  images: z
    .array(productImageS)
    .min(
      process.env.NODE_ENV === 'development' ? 0 : 2,
      'Must add at least 2 images'
    ),
  brandId: z.string().or(z.number()),
  tags: z.array(z.string()).default([]),
  inPackage: z.array(z.string()).default([]),
  maxStockValue: zodNumber.default(50),
  weight: zodNumber,
  discountPercentage: zodNumber.default(0),
  status: productS.shape.status.default('IN_REVIEW'),
  returnPolicy: z.string(),
})
  .omit({
    defaultPrice: true,
    slug: true,
    storeId: true,
    internal: true,
    sold: true,
    id: true,
    updatedAt: true,
    createdAt: true,
    viewsCount: true,
  })
  .extend({
    variants: variantS,
    otherBrands: z.string().optional(),
  })
  .superRefine(({variants}, ctx) => {
    variants.forEach((variant, variantIndex) => {
      variant.attributes.forEach((attribute, attrIndex) => {
        const duplicateName = variant.attributes.some(
          (otherAttr, otherAttrIndex) =>
            otherAttrIndex !== attrIndex && attribute.name === otherAttr.name
        );

        if (duplicateName) {
          ctx.addIssue({
            code: 'custom',
            message: `Must be unique`,
            path: ['variants', variantIndex, 'attributes', attrIndex, 'name'],
          });
        }
      });
      variant.attributes.forEach((attr, attrIndex) => {
        const duplicateOption = variants.some(
          (otherVariant, otherVariantIndex) => {
            return (
              variantIndex !== otherVariantIndex &&
              otherVariant.attributes.some(
                otherAttr =>
                  otherAttr.name === attr.name &&
                  otherAttr.option === attr.option
              )
            );
          }
        );

        if (duplicateOption) {
          ctx.addIssue({
            code: 'custom',
            message: `Must be unique`,
            path: ['variants', variantIndex, 'attributes', attrIndex, 'option'],
          });
        }
      });
    });
  });

export type ProductFormS = z.infer<typeof productFormS>;
