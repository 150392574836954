import {relations} from 'drizzle-orm';
import {
  integer,
  jsonb,
  pgEnum,
  pgTable,
  serial,
  timestamp,
} from 'drizzle-orm/pg-core';
import {storeOrders} from './order';
import {stores} from './store';

export const payoutStatus = pgEnum('payout_status', [
  'PENDING',
  'PAID',
  'STALLED',
]);

export const payouts = pgTable('payouts', {
  id: serial('id').primaryKey(),
  finalAmount: integer('final_amount').notNull(),
  commissionsValue: integer('commissions_value').notNull(),
  paidAt: timestamp('paid_at', {
    withTimezone: true,
  }),
  updated: timestamp('updated_at', {
    withTimezone: true,
  })
    .notNull()
    .$defaultFn(() => new Date()),
  paidTo: jsonb('paid_to'),
  storeId: integer('store_id')
    .references(() => stores.id)
    .notNull(),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .notNull()
    .defaultNow(),
  status: payoutStatus('status').notNull().default('PENDING'),
});

export const payoutsRelations = relations(payouts, ({one, many}) => ({
  storeOrders: many(storeOrders),
  store: one(stores, {
    fields: [payouts.storeId],
    references: [stores.id],
  }),
}));

// export const storeOrdersToPayouts = pgTable(
//   "store_orders_to_payouts",
//   {
//     storeOrderId: integer("store_order_id")
//       .notNull()
//       .references(() => storeOrders.id),
//     addedAt: timestamp("added_at", { withTimezone: true })
//       .defaultNow()
//       .notNull(),
//     payoutId: integer("payout_id")
//       .notNull()
//       .references(() => payouts.id),
//   },
//   (t) => ({
//     pk: primaryKey({ columns: [t.payoutId, t.storeOrderId] }),
//   }),
// );

// export const storeOrdersToPayoutsRelations = relations(
//   storeOrdersToPayouts,
//   ({ one }) => ({
//     storeOrders: one(storeOrders, {
//       fields: [storeOrdersToPayouts.storeOrderId],
//       references: [storeOrders.id],
//     }),
//     payout: one(payouts, {
//       fields: [storeOrdersToPayouts.payoutId],
//       references: [payouts.id],
//     }),
//   }),
// );
