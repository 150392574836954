import {relations} from 'drizzle-orm';
import {
  bigserial,
  integer,
  json,
  jsonb,
  pgEnum,
  pgTable,
  primaryKey,
  serial,
  text,
  timestamp,
  unique,
  varchar,
} from 'drizzle-orm/pg-core';
import {nanoid} from 'nanoid';
import {addHours, statusEnum} from '.';
import {customers, sessions} from './customer';
import {products} from './product';
import {stores, StoreWithFollowers, vendors} from './store';

export const tokenPurpose = pgEnum('token_purpose', [
  'PASSWORD_RESET',
  'NEW_STORE',
]);

export const tokens = pgTable('tokens', {
  id: serial('id').primaryKey(),
  token: varchar('token', {
    length: 256,
  })
    .notNull()
    .$defaultFn(() => nanoid(55)),
  purpose: tokenPurpose('purpose').notNull(),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .notNull()
    .defaultNow(),
  expiresAt: timestamp('expires_at', {
    withTimezone: true,
  })
    .notNull()
    .$defaultFn(() => addHours(new Date(), 48)),
  usedAt: timestamp('used_at', {
    withTimezone: true,
  }),
  email: varchar('email', {
    length: 256,
  }).notNull(),
  storeId: integer('store_id').references(() => stores.id),
});

export const tokensRelations = relations(tokens, ({one}) => ({
  store: one(stores, {
    fields: [tokens.storeId],
    references: [stores.id],
  }),
}));

export interface PostMedia {
  type: 'image' | 'video';
  url: string;
}

export const posts = pgTable('posts', {
  id: serial('id').primaryKey(),
  caption: varchar('caption', {
    length: 256,
  }),
  medias: json('medias').notNull().$type<PostMedia[]>(),
  storeId: integer('store_id').references(() => stores.id),
  status: statusEnum('status'),
  image: varchar('image', {
    length: 256,
  }),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
  }),
});

export const postRelations = relations(posts, ({one, many}) => ({
  liked: many(likedPosts),
  store: one(stores, {
    fields: [posts.storeId],
    references: [stores.id],
  }),
}));

export type Post = typeof posts.$inferSelect;
export type PostWithStore = Post & {
  store: StoreWithFollowers | null;
};

export const likedPosts = pgTable(
  'liked_posts',
  {
    likedAt: timestamp('liked_at', {
      withTimezone: true,
    })
      .defaultNow()
      .notNull(),
    customerId: integer('customer_id')
      .notNull()
      .references(() => customers.id),
    postId: integer('post_id')
      .notNull()
      .references(() => posts.id),
  },
  t => ({
    pk: primaryKey({
      columns: [t.customerId, t.postId],
    }),
  })
);

export const likedPostsRelations = relations(likedPosts, ({one}) => ({
  customer: one(customers, {
    fields: [likedPosts.customerId],
    references: [customers.id],
  }),
  post: one(posts, {
    fields: [likedPosts.postId],
    references: [posts.id],
  }),
}));

export const activityType = pgEnum('activity_type', [
  'PRODUCT_VIEW',
  'PRODUCT_SHARE',
]);

export const activities = pgTable(
  'activities',
  {
    id: bigserial('id', {
      mode: 'bigint',
    }).primaryKey(),
    type: activityType('type').notNull(),
    productId: integer('product_id')
      .references(() => products.id)
      .notNull(),
    customerId: integer('customer_id').references(() => customers.id),
    sessionId: integer('session_id').references(() => sessions.id),
    createdAt: timestamp('created_at', {
      withTimezone: true,
    })
      .defaultNow()
      .notNull(),
    updatedAt: timestamp('updated_at', {
      withTimezone: true,
      mode: 'date',
    })
      .notNull()
      .defaultNow()
      .$onUpdateFn(() => new Date()),
    count: integer('count').notNull().default(1),
  },
  table => ({
    uniqueCustomerProduct: unique().on(table.customerId, table.productId),
    uniqueSessionProduct: unique().on(table.sessionId, table.productId),
  })
);

export const activitiesRelations = relations(activities, ({one}) => ({
  product: one(products, {
    fields: [activities.productId],
    references: [products.id],
  }),
}));

export const searches = pgTable(
  'searches',
  {
    id: serial('id').primaryKey(),
    customerId: integer('customer_id').references(() => customers.id),
    hint: varchar('hint', {
      length: 256,
    }).notNull(),
    clickedProductId: integer('clicked_product_id').references(
      () => products.id
    ),
    createdAt: timestamp('created_at', {
      withTimezone: true,
    })
      .notNull()
      .defaultNow(),
    updatedAt: timestamp('updated_at', {
      withTimezone: true,
    })
      .notNull()
      .defaultNow()
      .$onUpdateFn(() => new Date()),
    clickBack: integer('click_back').notNull().default(0),
  },
  table => ({
    uniqueHintPerCustomer: unique().on(table.hint, table.customerId),
  })
);

export const searchesRelations = relations(searches, ({one}) => ({
  customer: one(customers, {
    fields: [searches.customerId],
    references: [customers.id],
  }),
  clickedProduct: one(products, {
    fields: [searches.clickedProductId],
    references: [products.id],
  }),
}));

export const notificationType = pgEnum('notification_type', [
  'SUPPORT',
  'COMPLAINT',
  'ORDER',
  'PREBOARD',
]);

export const notifications = pgTable('notifications', {
  id: serial('id').primaryKey(),
  vendorId: integer('vendor_id').references(() => vendors.id),
  status: statusEnum('status'),
  type: notificationType('type'),
  title: varchar('title', {
    length: 256,
  }),
  body: text('body'),
  sentAt: timestamp('sent_at', {
    withTimezone: true,
    mode: 'date',
  })
    .notNull()
    .defaultNow(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
    mode: 'date',
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
  openedAt: timestamp('opened_at', {
    withTimezone: true,
    mode: 'date',
  }),
  emailMessageId: varchar('email_message_id', {length: 256}),
  data: jsonb('data'),
});

export const notificationsRelations = relations(notifications, ({one}) => ({
  vendor: one(vendors, {
    fields: [notifications.vendorId],
    references: [vendors.id],
  }),
}));

export const contacts = pgTable('contacts', {
  id: serial('id').primaryKey(),
  email: varchar('email', {
    length: 256,
  }),
  name: varchar('name', {
    length: 256,
  }),
  subject: varchar('subject', {
    length: 256,
  }),
  message: varchar('message', {
    length: 3000,
  }).notNull(),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
});

// export const contactsRelations = relations(posts, ({ one, many }) => ({}));

export interface Rank {
  cids: number[];
  sids: number[];
  bids: number[];
  tags: string[];
}

export const rankType = pgEnum('rank_type', ['CUSTOMER', 'SESSION', 'DEFAULT']);

export const ranks = pgTable('ranks', {
  id: serial('id').primaryKey(),
  customerId: integer('customer_id')
    .references(() => customers.id)
    .unique(),
  type: rankType('type').notNull(),
  data: jsonb('data').notNull().$type<Rank>(),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
});

export const ranksRelations = relations(ranks, ({one}) => ({
  customer: one(customers, {
    fields: [ranks.customerId],
    references: [customers.id],
  }),
}));
