import {relations} from 'drizzle-orm';
import {
  boolean,
  integer,
  jsonb,
  pgEnum,
  pgTable,
  primaryKey,
  real,
  serial,
  text,
  timestamp,
  unique,
  varchar,
} from 'drizzle-orm/pg-core';
import {Location, statusEnum} from '.';
import {ranks} from './base';
import {storeChats} from './chat';
import {storeOrders} from './order';
import {
  coupons,
  products,
  productsLikes,
  reviews,
  Variant,
  variants,
} from './product';
import {storeFollowers} from './store';

export const customers = pgTable('customers', {
  id: serial('id').primaryKey(),
  email: varchar('email', {
    length: 256,
  })
    .notNull()
    .unique(),
  firstName: varchar('first_name', {
    length: 256,
  }).notNull(),
  lastName: varchar('last_name', {
    length: 256,
  }),
  userName: varchar('user_name', {
    length: 256,
  }).notNull(),
  phoneNumber: varchar('phone_number', {length: 256}),
  promotionalEmails: boolean('promotional_emails').default(true),
  bio: text('bio'),
  image: varchar('image', {
    length: 256,
  }),
  internal: boolean('internal'),
  createdAt: timestamp('created_at', {
    mode: 'date',
  })
    .notNull()
    .defaultNow(),
  status: statusEnum('status').default('ACTIVE'),
});

export const customerRelations = relations(customers, ({one, many}) => ({
  userCoupons: many(customerToCoupon),
  shippingDetails: many(shippingDetails),
  followers: many(customerFollows, {
    relationName: 'follower',
  }),
  following: many(customerFollows, {
    relationName: 'following',
  }),
  followingStores: many(storeFollowers),
  cart: many(carts),
  reviews: many(reviews),
  credentials: one(credentialsAuths),
  googleAuth: one(googleAuths),
  productsLikes: many(productsLikes),
  wallet: one(wallets),
  storeChats: many(storeChats),
  rank: one(ranks),
}));

export const customerFollows = pgTable(
  'customer_follow',
  {
    followerBy: integer('follower_id')
      .notNull()
      .references(() => customers.id),
    followedAt: timestamp('followed_at', {withTimezone: true})
      .defaultNow()
      .notNull(),
    followerTo: integer('followee_id')
      .notNull()
      .references(() => customers.id),
  },
  t => ({
    pk: primaryKey({
      columns: [t.followerBy, t.followerTo],
    }),
  })
);

export const customerFollowsRelations = relations(customerFollows, ({one}) => ({
  follower: one(customers, {
    fields: [customerFollows.followerTo],
    references: [customers.id],
    relationName: 'follower',
  }),
  following: one(customers, {
    fields: [customerFollows.followerBy],
    references: [customers.id],
    relationName: 'followee',
  }),
}));

export type Customer = typeof customers.$inferSelect;

export const shippingDetails = pgTable('shipping_details', {
  id: serial('id').primaryKey(),
  customerId: integer('customer_id')
    .references(() => customers.id)
    .notNull(),
  address: text('address').notNull(),
  email: varchar('email', {
    length: 256,
  }).notNull(),
  firstName: varchar('first_name', {
    length: 256,
  }).notNull(),
  lastName: varchar('last_name', {
    length: 256,
  }).notNull(),
  phoneNumber: varchar('phone_number', {length: 256}).notNull(),
  state: varchar('state', {
    length: 256,
  }).notNull(),
  city: varchar('city', {
    length: 256,
  }).notNull(),
  default: boolean('default').default(false),
});

export const shippingDetailsRelations = relations(shippingDetails, ({one}) => ({
  customer: one(customers, {
    fields: [shippingDetails.customerId],
    references: [customers.id],
  }),
}));

export type ShippingDetails = typeof shippingDetails.$inferSelect;
export type CustomerWithAddresses = typeof customers.$inferSelect & {
  shippingDetails: ShippingDetails[];
};

export const customerToCoupon = pgTable(
  'customers_to_coupons',
  {
    customerId: integer('customer_id')
      .notNull()
      .references(() => customers.id),
    addedAt: timestamp('added_at', {
      withTimezone: true,
    })
      .defaultNow()
      .notNull(),
    couponId: integer('coupon_id')
      .notNull()
      .references(() => coupons.id),
    usedAt: timestamp('used_at', {
      withTimezone: true,
    }),
  },
  t => ({
    pk: primaryKey({
      columns: [t.customerId, t.couponId],
    }),
  })
);

export const customerToCouponRelations = relations(
  customerToCoupon,
  ({one}) => ({
    customer: one(customers, {
      fields: [customerToCoupon.customerId],
      references: [customers.id],
    }),
    coupon: one(coupons, {
      fields: [customerToCoupon.couponId],
      references: [coupons.id],
    }),
  })
);

export const carts = pgTable('carts', {
  id: serial('id').primaryKey(),
  customerId: integer('customer_id')
    .references(() => customers.id)
    .unique(),
  sessionId: integer('session_id')
    .references(() => sessions.id)
    .unique(),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .notNull()
    .defaultNow(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
    mode: 'date',
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
  storesCarriers: jsonb('stores_carriers')
    .$type<{storeId: number; cid: number}[]>()
    .notNull()
    .default([]),
});

export const cartsRelations = relations(carts, ({one, many}) => ({
  customer: one(customers, {
    fields: [carts.customerId],
    references: [customers.id],
  }),
  session: one(sessions, {
    fields: [carts.sessionId],
    references: [sessions.id],
  }),
  items: many(cartItems),
}));

export const cartItems = pgTable(
  'cart_items',
  {
    id: serial('id').primaryKey(),
    cartId: integer('cart_id')
      .references(() => carts.id)
      .notNull(),
    variantId: integer('variant_id')
      .references(() => variants.id)
      .notNull(),
    quantity: integer('quantity').notNull().default(1),
    addedAt: timestamp('added_at', {
      withTimezone: true,
    })
      .notNull()
      .defaultNow(),
    modifiedAt: timestamp('modified_at', {withTimezone: true})
      .notNull()
      .defaultNow()
      .$onUpdateFn(() => new Date()),
  },
  table => ({
    uniqueItemPerCart: unique().on(table.cartId, table.variantId),
  })
);

export const cartItemsRelations = relations(cartItems, ({one}) => ({
  variant: one(variants, {
    fields: [cartItems.variantId],
    references: [variants.id],
  }),
  cart: one(carts, {
    fields: [cartItems.cartId],
    references: [carts.id],
  }),
}));

export type CartItem = typeof cartItems.$inferSelect & {
  variant: Variant & {
    product: typeof products.$inferSelect;
  };
};

export type Cart = typeof carts.$inferSelect;

export const credentialsAuths = pgTable('credentials_auths', {
  id: serial('id').primaryKey(),
  customerId: integer('customer_id')
    .references(() => customers.id)
    .notNull()
    .unique(),
  email: varchar('email', {
    length: 256,
  })
    .notNull()
    .unique(),
  encryptedPassword: varchar('encrypted_password', {length: 256}).notNull(),
  status: statusEnum('status').default('ACTIVE'),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  modifiedAt: timestamp('modified_at', {withTimezone: true}),
  lastSignedIn: timestamp('last_signed_in'),
});

export const credentialsAuthsRelations = relations(
  credentialsAuths,
  ({one}) => ({
    customer: one(customers, {
      fields: [credentialsAuths.customerId],
      references: [customers.id],
    }),
  })
);

export const googleAuths = pgTable('google_auths', {
  id: serial('id').primaryKey(),
  customerId: integer('customer_id')
    .references(() => customers.id)
    .notNull()
    .unique(),
  email: varchar('email', {
    length: 256,
  })
    .notNull()
    .unique(),
  sub: varchar('sub', {length: 256}).notNull().unique(),
  status: statusEnum('status').default('ACTIVE'),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  lastSignedIn: timestamp('last_signed_in', {withTimezone: true}),
});

export const googleAuthsRelations = relations(googleAuths, ({one}) => ({
  customer: one(customers, {
    fields: [googleAuths.customerId],
    references: [customers.id],
  }),
}));

export const pendingBalanceStatus = pgEnum('pending_balance_status', [
  'PENDING',
  'SETTLED',
]);
export const pendingBalanceType = pgEnum('pending_balance_type', [
  'ORDER_CANCELED',
]);

export const wallets = pgTable('wallets', {
  id: serial('id').primaryKey(),
  customerId: integer('customer_id')
    .references(() => customers.id)
    .notNull()
    .unique(),
  balance: real('balance').notNull().default(0),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
    mode: 'date',
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
  status: statusEnum('status').notNull().default('ACTIVE'),
  internal: boolean('internal'),
});

export type Wallet = typeof wallets.$inferSelect;
export type WalletWithPending = Wallet & {
  pendingBalances: (typeof pendingBalances.$inferSelect)[];
};

export const walletRelations = relations(wallets, ({one, many}) => ({
  customer: one(customers, {
    fields: [wallets.customerId],
    references: [customers.id],
  }),
  pendingBalances: many(pendingBalances),
}));

export const pendingBalances = pgTable('pending_balances', {
  id: serial('id').primaryKey(),
  customerId: integer('customer_id')
    .references(() => customers.id)
    .notNull(),
  walletId: integer('wallet_id')
    .references(() => wallets.id)
    .notNull(),
  storeOrderId: integer('store_order_id').references(() => storeOrders.id),
  value: real('value').notNull().default(0),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
    mode: 'date',
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
  settledAt: timestamp('settled_at', {
    withTimezone: true,
  }),
  status: pendingBalanceStatus('status').notNull().default('PENDING'),
  type: pendingBalanceType('type').notNull().default('ORDER_CANCELED'),
  internal: boolean('internal'),
});

export const pendingBalancesRelations = relations(pendingBalances, ({one}) => ({
  customer: one(customers, {
    fields: [pendingBalances.customerId],
    references: [customers.id],
  }),
  wallet: one(wallets, {
    fields: [pendingBalances.walletId],
    references: [wallets.id],
  }),
}));

export const sessions = pgTable('sessions', {
  id: serial('id').primaryKey(),
  internal: boolean('internal'),
  location: jsonb('location').$type<Omit<Location, 'address'>>(),
  ip: varchar('ip', {length: 256}),
  userAgent: varchar('user_agent', {
    length: 256,
  }),
  customerId: integer('customer_id').references(() => customers.id),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  //Let updated at remain not null to tract active users including getting rank!
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
    mode: 'date',
  })
    .notNull()
    .defaultNow()
    .$onUpdateFn(() => new Date()),
});

export const sessionsRelations = relations(sessions, ({one}) => ({
  cart: one(carts),
  customer: one(customers, {
    fields: [sessions.customerId],
    references: [customers.id],
  }),
  // productsLikes: many(productsLikes),
}));
