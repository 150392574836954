import {relations, sql} from 'drizzle-orm';
import {
  boolean,
  index,
  integer,
  json,
  jsonb,
  pgEnum,
  pgTable,
  primaryKey,
  real,
  serial,
  text,
  timestamp,
  unique,
  varchar,
} from 'drizzle-orm/pg-core';
import {statusEnum} from '.';
import {customers, customerToCoupon} from './customer';
import {promotions} from './operation';
import {Store, stores} from './store';

export interface Attribute {
  name: string;
  option: string;
}

export interface Attributes {
  name: string;
  options: string[];
}

export const couponType = pgEnum('coupon_type', [
  'FREE_SHIPPING',
  'BOGO',
  'FIXED',
  'PERCENTAGE',
]);

// export const couponRemptionLimit = pgEnum('coupon_redemption_type', [
//   'SINGLE',
//   'MULTIPLE',
// ])

export const coupons = pgTable('coupons', {
  id: serial('id').primaryKey(),
  name: varchar('name', {
    length: 256,
  }),
  activeAt: timestamp('active_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  expiresAt: timestamp('expires_at', {
    withTimezone: true,
  }).notNull(),
  storeId: integer('store_id').references(() => stores.id),
  type: couponType('type').default('FIXED').notNull(),
  value: integer('value'),
  minPurchaseAmount: integer('min_purchase_amount'),
  maxDiscountedValue: integer('max_discounted_value'),
  redemptionLimit: integer('redemption_limit'), //This is per customer. If null no limit per customer
  maxRedemptionLimit: integer('max_redemption_limit').notNull(), //This is in total
  status: statusEnum('status'),
  freeShippingStates: text('free_shipping_states'),
  public: boolean('public'),
  internal: boolean('internal'),
  code: varchar('code', {length: 15}),
  redeemedBy: integer('redeemed_by').array().default([]),
});

export type Coupon = typeof coupons.$inferSelect;

export const couponsRelations = relations(coupons, ({one, many}) => ({
  bearers: many(customerToCoupon),
  store: one(stores, {
    fields: [coupons.storeId],
    references: [stores.id],
  }),
}));

export const categories = pgTable('categories', {
  id: serial('id').primaryKey(),
  name: varchar('name', {
    length: 256,
  }).notNull(),
  slug: varchar('slug', {
    length: 256,
  }).notNull(),
  level: integer('level').notNull(),
  disabled: boolean('disabled').notNull().default(false),
  commission: integer('commission'),
  minPrice: integer('min_price').notNull().default(500),
  maxPrice: integer('max_price').notNull().default(50000000),
  attributes: jsonb('attributes').$type<Attributes[]>().default([]),
  parentId: integer('parent_id'),
});

export const categoriesRelations = relations(categories, ({one, many}) => ({
  products: many(products),
  parent: one(categories, {
    fields: [categories.parentId],
    references: [categories.id],
  }),
}));

export type Category = typeof categories.$inferSelect;

export const productsLikes = pgTable(
  'liked_products',
  {
    customerId: integer('customer_id')
      .notNull()
      .references(() => customers.id),
    likedAt: timestamp('liked_at', {
      withTimezone: true,
    })
      .defaultNow()
      .notNull(),
    productId: integer('product_id')
      .notNull()
      .references(() => products.id),
  },
  t => ({
    pk: primaryKey({
      columns: [t.customerId, t.productId],
    }),
  })
);

export const likedProductsRelations = relations(productsLikes, ({one}) => ({
  customer: one(customers, {
    fields: [productsLikes.customerId],
    references: [customers.id],
  }),
  product: one(products, {
    fields: [productsLikes.productId],
    references: [products.id],
  }),
}));

export interface ProductImage {
  id: string;
  url: string;
}

export const productCondition = pgEnum('product_condition', [
  'THRIFTED',
  'NEW',
]);

export const products = pgTable(
  'products',
  {
    id: serial('id').primaryKey(),
    categoryId: integer('category_id')
      .references(() => categories.id)
      .notNull(),
    storeId: integer('store_id')
      .references(() => stores.id)
      .notNull(),
    brandId: integer('brand_id').references(() => brands.id),
    title: varchar('title', {
      length: 256,
    }).notNull(),
    slug: varchar('slug', {
      length: 256,
    }).notNull(),
    description: text('description').notNull(),
    discountPercentage: real('discount_percentage').notNull().default(0),
    images: json('images').notNull().$type<ProductImage[]>(),
    status: statusEnum('status').notNull().default('IN_REVIEW'),
    inPackage: varchar('in_package', {
      length: 256,
    })
      .array()
      .notNull(),
    condition: productCondition('condition'),
    createdAt: timestamp('created_at', {
      withTimezone: true,
    })
      .defaultNow()
      .notNull(),
    updatedAt: timestamp('updated_at', {
      withTimezone: true,
      mode: 'date',
    })
      .defaultNow()
      .$onUpdateFn(() => new Date()),
    maxStockValue: integer('max_stock_value').notNull().default(50),
    sold: integer('sold').notNull().default(0),
    defaultPrice: real('default_price').notNull(),
    viewsCount: integer('views_count').notNull().default(0),
    returnPolicy: varchar('return_policy', {length: 256}).notNull(),
    internal: boolean('internal'),
    tags: varchar('tags', {length: 256}).array().notNull(),
    shippingInformation: varchar('shipping_information', {length: 256}),
    weight: real('weight').notNull(),
  },
  table => ({
    searchIndex: index('search_index').using(
      'gin',
      sql`(
          setweight(to_tsvector('english', ${table.title}), 'A') ||
          setweight(to_tsvector('english', ${table.description}), 'B')
      )`
    ),
  })
);

export const variants = pgTable('variants', {
  id: serial('id').primaryKey(),
  productId: integer('product_id')
    .references(() => products.id)
    .notNull(),
  price: real('price').notNull(),
  sku: varchar('sku', {
    length: 256,
  }).notNull(),
  stock: integer('stock').notNull(),
  attributes: jsonb('attributes').$type<Attribute[]>().notNull(),
});

export const variantsRelations = relations(variants, ({one}) => ({
  product: one(products, {
    fields: [variants.productId],
    references: [products.id],
  }),
}));

export type Variant = typeof variants.$inferSelect;
export type Product = typeof products.$inferSelect & {
  variants: Variant[];
};
export type ProductWithLikes = Product & {
  productLikes: (typeof productsLikes.$inferSelect)[];
};
export type ProductWithStore = Product & {store: Store};

export const productsRelations = relations(products, ({one, many}) => ({
  variants: many(variants),
  reviews: many(reviews),
  store: one(stores, {
    fields: [products.storeId],
    references: [stores.id],
  }),
  category: one(categories, {
    fields: [products.categoryId],
    references: [categories.id],
  }),
  brand: one(brands, {
    fields: [products.brandId],
    references: [brands.id],
  }),
  promotions: many(promotions),
  productLikes: many(productsLikes),
}));

export const reviewsLabel = pgEnum('reviews_label', ['POSITIVE', 'NEGATIVE']);

export const reviews = pgTable(
  'reviews',
  {
    id: serial('id').primaryKey(),
    rating: real('rating').notNull().default(0),
    authorId: integer('customer_id')
      .references(() => customers.id)
      .notNull(),
    body: text('body').notNull().default(''),
    productId: integer('product_id')
      .references(() => products.id)
      .notNull(),
    storeId: integer('store_id')
      .references(() => stores.id)
      .notNull(),
    createdAt: timestamp('created_at', {
      withTimezone: true,
    })
      .defaultNow()
      .notNull(),
    updatedAt: timestamp('updated_at', {
      withTimezone: true,
    }),
    label: reviewsLabel('label').notNull().default('POSITIVE'),
    images: varchar('images', {length: 256}).array().notNull().default([]),
    status: statusEnum('status').default('IN_REVIEW'),
  },
  table => ({
    uniquePerCustomer: unique().on(table.authorId, table.productId),
  })
);

export type Review = typeof reviews.$inferSelect;

export const reviewsRelations = relations(reviews, ({one}) => ({
  author: one(customers, {
    fields: [reviews.authorId],
    references: [customers.id],
  }),
  product: one(products, {
    fields: [reviews.productId],
    references: [products.id],
  }),
}));

export const brands = pgTable('brands', {
  id: serial('id').primaryKey(),
  name: varchar('name', {length: 256}).notNull().unique(),
  image: varchar('image', {
    length: 256,
  }),
  createdAt: timestamp('created_at', {
    withTimezone: true,
  })
    .defaultNow()
    .notNull(),
  updatedAt: timestamp('updated_at', {
    withTimezone: true,
  }),
});

export const brandRelations = relations(brands, ({many}) => ({
  products: many(products),
  // categories: many(categoryT),
}));

export type Brand = typeof brands.$inferSelect;
